@import '../../../styles/variables';

.station-details {

    .map-tile {
        padding: 10px;
        border-right: 1px solid $text--light;
    }

    .close {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
    }

    .tab-cont {
        display: flex;
        padding: 10px;
        position: relative;

        .tab-tiles {
            width: 20%;
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .tab-tile {
                display: flex;
                align-items: center;
                justify-content: start;
                padding: 0px 8px;
                gap: 4px;
                position: relative;
                height: 100%;
                width: 100%;
                max-height: 54px;
                box-shadow: $box-shadow;
                font-weight: 700;
                filter: brightness(2.5);
                cursor: pointer;
    
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: 10px;
                    right: -5px;
                    top: 0px;
                    background-color: $white;
                }

                &.active {
                    filter: none;
                }

                .material-icon {
                    color: $text;
                    font-size: 24px;
                }
            }
        }

        .tab-card {
            display: none;
            height: 100%;
            width: 80%;
            box-shadow: $box-shadow;

            &.active {
                display: inline;
            }

            .tab-cont {
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 12px 20px;

                // ticket tab
                .ticket-top {
                    height: 45%;
                    min-height: 140px;
                    display: flex;
                    width: 100%;

                    .col-4 {
                        padding: 0;
                    }

                    .auto-rec {
                        font-weight: 500;

                        .recovery-option {
                            padding: 2px 0px;
                            font-size: 12px;
                            width: 90%;
                            
                            &:first-child {
                                margin-top: 6px;
                            }

                            label {
                                display: flex;
                                align-items: center;
                                justify-content: start;
                                gap: 4px;
                                cursor: pointer;
                                
                                input[type=checkbox] {
                                    border-color: $grey;
    
                                    &:checked {
                                        border-color: $text;
                                    }

                                    &::before {
                                        color: $text;
                                    }
                                }
                            }
                        }
                    }

                    .aff-conn {
                        font-weight: 500;

                        .connector-selection {
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            margin-top: 10px;

                            .btn-select-station,
                            .btn-select-connector {
                                border: none;
                                outline: none;
                                background-color: $white;
                                cursor: pointer;
                                background-position: center;
                                background-repeat: no-repeat;
                                color: $text;
                                font-weight: 600;
                            }
                            
                            .btn-select-station {
                                position: relative;
                                height: 25px;
                                width: 25px;
                            
                                &::after {
                                    content: "";
                                    position: absolute;
                                    display: block;
                                    top: 0;
                                    left: 0;
                                    height: 100%;
                                    width: 100%;
                                    background-color: $primary;
                                    mask-image: url('/assets/icons/charging-station-turqouise.svg');
                                    mask-size: 25px 25px;
                                    mask-position: center;
                                    mask-repeat: no-repeat;
                                    filter: contrast(0) brightness(1.4);
                                    transition: filter $animation-duration ease;
                                }
                            
                                &.active {
                                    &::after {
                                        filter: none;
                                    }
                                }
                            }
                            .select-connector-container {
                                height: 23px;
                                width: 23px;
                                position: relative;
                                cursor: pointer;
                            
                                .btn-select-connector {
                                    width: 100%;
                                    height: 100%;
                                    mask-size: 23px 23px;
                                    background-image: none;
                                    background-color: $primary;
                                    mask-image: url('/assets/icons/connector-inactive.svg');
                                    mask-position: center;
                                    mask-repeat: no-repeat;
                                    filter: contrast(0) brightness(1.4);
                                
                                    &.active {
                                        background-image: none;
                                        background-color: $primary;
                                        mask-image: url('/assets/icons/connector-active.svg');
                                        mask-position: center;
                                        mask-repeat: no-repeat;
                                        color: white;
                                        filter: none;
                                    }
                                }
                            
                                .connector-index {
                                    color: $primary;
                                    font-size: 10px;
                                    display: flex;
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    align-items: center;
                                    justify-content: center;
                                    transition: all $animation-duration ease;
                                    filter: contrast(0);
                            
                                    &.active {
                                        color: white;
                                        filter: none;
                                    }
                                }
                            }
                        }
                    }

                    .start-def {
                        font-weight: 500;
                        
                        .date-picker-cont {
                            margin-top: 8px;

                            .ant-picker {
                                padding: 0;
                                border: none;
                                cursor: pointer;

                                .ant-picker-input {
                                    flex-direction: row-reverse;

                                    .ant-picker-suffix {margin: 0 4px 0 0}
                                    .ant-picker-clear {display: none}
                                    input {cursor: pointer}
                                }
                            }
                        }
                    }
                }

                .add-desc {
                    height: 105.5px;
                    font-weight: 500;

                    textarea {
                        display: flex;
                        width: 100%;
                        margin-top: 8px;
                        padding: 8px 10px;
                        background-color: $off-white;
                        border-radius: 4px;
                        box-shadow: $box-shadow;
                        border: 1px solid $off-white;
                        transition: all .3s ease-in-out;
                        resize: none;

                        &:focus {
                            outline: none;
                            border: 1px solid $primary;
                        }
                    }
                }

                .submit-btn {
                    color: white;
                    height: 32px;
                    display: flex;
                    padding: 8px 20px;
                    white-space: nowrap;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    border-radius: 4px;
                    opacity: .2;
                    background: $primary;
                    margin-left: calc(100% - 150px);
                    margin-top: 39.5px;
                    filter: grayscale(1);
                    cursor: pointer;

                    .material-icon {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.station-details-header {
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    pointer-events: none;

    .station-details-cont {
        display: flex;
        gap: 10px;
        height: 70px;
        width: 96%;
        padding: 8px 10px;

        .v-divider {
            border-left: .5px dashed $grey--strong;
        }

        .info-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            overflow: hidden;
            pointer-events: all;

            .info-box {
                display: flex;
                flex-direction: column;
                gap: 2px;
                align-items: start;
                justify-content: center;
                padding-right: 12px;
            }

            .info-title {
                white-space: nowrap;
                font-weight: 500;
                line-height: 18px;
            }

            .info-sub {
                white-space: nowrap;
                line-height: 18px;
                color: $grey--strong;
            }

            .material-icon {
                font-size: 20px;
                padding-left: 4px;
                padding-right: 4px;
                &:first-of-type {padding-left: 0px}
                &:last-of-type {padding-right: 0px}
                &.rec-icon {font-size: 36px}
            }

            .state-cont {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .state-row {
                    display: flex;
                    justify-content: center;
                }

                .state-bubble {
                    color: $white;
                    line-height: 1.2;
                    border-radius: 100px;
                    // padding for better visual balance
                    padding: 1px 9px 2px 8px;
                    white-space: nowrap;
                }
            }
        }
    }
}
